import {
  Flex,
  Loader,
  ScrollView,
  ThemeProvider,
  Theme,
} from "@aws-amplify/ui-react";
import React, { useState, useEffect } from "react";
import { Category, Post } from "../models/index";
import { DataStore } from "aws-amplify";
import {
  CustomNavBar,
  CustomSortBar,
  CustomMenuBar,
  CustomPostPreviewCollection,
} from "../ui-components";
import { useBreakpointValue } from "@aws-amplify/ui-react";
import { SortDirection } from "@aws-amplify/datastore";
import data from "./data.json";
import ProfilePicture from "./profil_picture.jpg";

function BlogPosts() {
  const [menu, setMenu] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [items, setItems] = React.useState([]);
  const [sortBy, setSortBy] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("");
  const categories = [
    "All",
    "Architecture",
    "Containers",
    "Database",
    "DevOps",
    "DisasterRecovery",
    "MachineLearning",
    "Migration",
    "Network",
    "Operations",
    "Security",
    "Serverless",
    "Storage",
  ];
  const sort = [
    "Latest",
    "Popular",
    // "TrendingLast30Days",
    "Oldest",
  ];

  const itemsPerPage = useBreakpointValue({
    base: 10,
    small: 10,
    medium: 20,
    large: 30,
    xl: 40,
  });

  const theme: Theme = {
    name: "selectfield-theme",
    tokens: {
      components: {
        selectfield: {
          color: { value: "{colors.white}" },
          borderColor: { value: "{colors.white}" },
          _focus: {
            borderColor: { value: "{colors.white}" },
          },
          label: {
            color: { value: "{colors.white}" },
          },
        },
      },
    },
  };

  useEffect(() => {
    retrieveItems(sortBy, filterBy, setItems, setDataLoaded);
  }, [sortBy, filterBy]);

  // Upload data to backend
  // useEffect(() => {
  //   data["posts"].forEach(post => {
  //     DataStore.save(
  //       new Post({
  //         title: post["title"],
  //         src: post["src"],
  //         img_src: post["img_src"],
  //         description: post["description"],
  //         category: post["category"],
  //         post_date: post["post_date"],
  //         linkedin_likes: post["linkedin_likes"],
  //         linkedin_views: post["linkedin_views"],
  //         linkedin_post: post["linkedin_post"]
  //       })
  //     );
  //   });
  // });

  return (
    <div
      className="BlogPosts"
      style={{ backgroundColor: "#F2F3F3", height: "100vh" }}
    >
      <CustomNavBar
        width={"100%"}
        overrides={{
          CustomMenuButton: {
            onClick: () => setMenu((prevMenu) => !prevMenu),
          },
          image: {
            src: ProfilePicture,
          },
        }}
      />
      {menu ? <CustomMenuBar width={"100%"} /> : null}
      <div>
        <ThemeProvider theme={theme}>
          <CustomSortBar
            overrides={{
              SelectField37143517: {
                placeholder: "",
                options: categories,
                onChange: (val) => {
                  setFilterBy(val.target.value);
                },
              },
              SelectField37082971: {
                placeholder: "",
                options: categories,
                onChange: (val) => {
                  setFilterBy(val.target.value);
                },
              },
              SelectField37082968: {
                placeholder: "",
                options: sort,
                onChange: (val) => {
                  setSortBy(val.target.value);
                },
              },
              SelectField37143514: {
                placeholder: "",
                options: sort,
                onChange: (val) => {
                  setSortBy(val.target.value);
                },
              },
            }}
          ></CustomSortBar>
        </ThemeProvider>
        <ScrollView width={"100%"} style={{ backgroundColor: "#F2F3F3" }}>
          <Flex
            justifyContent={"center"}
            padding={"20px"}
            paddingBottom={"50px"}
          >
            <CustomPostPreviewCollection
              searchNoResultsFound={
                <Flex justifyContent={"center"}>
                  <Loader size="large"></Loader>
                </Flex>
              }
              searchFilter={(item, searchText) =>
                item.title.toLowerCase().includes(searchText.toLowerCase()) ||
                item.description
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              }
              items={items}
              itemsPerPage={itemsPerPage}
              templateColumns={{
                small: "1fr",
                medium: "1fr 1fr",
                large: "1fr 1fr 1fr",
                xl: "1fr 1fr 1fr 1fr",
              }}
              overrideItems={(item, index) => ({
                height: {
                  base: "300px",
                  small: "300px",
                  medium: "400px",
                  large: "400px",
                  xl: "400px",
                },
                width: {
                  base: "320px",
                  small: "320px",
                  medium: "400px",
                  large: "400px",
                  xl: "400px",
                },
                // onClick: () => alert(`Home with id: ${item.id} and ${item.address} clicked!`)
              })}
            />
          </Flex>
        </ScrollView>
      </div>
    </div>
  );
}

async function retrieveItems(sortBy, filterBy, setItems, setDataLoaded) {
  try {
    var result = [];
    var sortRule = null;
    if (sortBy === "Oldest") {
      sortRule = { sort: (s) => s.createdAt(SortDirection.ASCENDING) };
    } else if (sortBy === "Popular") {
      sortRule = { sort: (s) => s.linkedin_likes(SortDirection.DESCENDING) };
    } else if (sortBy === "TrendingLast30Days") {
      var today = new Date();
      var priorDate = new Date(new Date().setDate(today.getDate() - 90));
      sortRule = { sort: (s) => s.createdAt > priorDate };
    } else {
      sortRule = { sort: (s) => s.createdAt(SortDirection.DESCENDING) };
    }

    DataStore.observeQuery(
      Post,
      (c) => c.category.contains(filterBy === "All" ? "" : filterBy),
      sortRule
    ).subscribe((snapshot) => {
      const { items, isSynced } = snapshot;
      // console.log(
      //   `[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`
      // );

      if (isSynced) {
        setDataLoaded(true);

        const posts = items;

        // console.log("Categories retrieved successfully!", JSON.stringify(posts, null, 2));
        posts.forEach((element) => result.push(element));
        setItems(result);
      }
    });
  } catch (error) {
    console.log("Error retrieving posts", error);
  }
}

export default BlogPosts;