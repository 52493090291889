import { Divider, Flex, ScrollView, Loader } from "@aws-amplify/ui-react";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  CustomMenuBar,
  CustomNavBar,
  CustomPost,
  CustomPostPreview,
} from "../ui-components";
import { Post } from "../models/index";
import { DataStore } from "aws-amplify";
import { SortDirection } from "@aws-amplify/datastore";
import ProfilePicture from "./profil_picture.jpg";

function BlogPost() {
  const [menu, setMenu] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [post, setPost] = useState({
    title: "",
    description: "",
    img_src: "",
    tags: "",
  });
  const [similarPosts, setSimilarPosts] = useState([]);
  const { id } = useParams();
  // console.log("ID:", id);

  useEffect(() => {
    retrievePost(id, setPost, setSimilarPosts, setDataLoaded);
  }, [id]);

  return (
    <div
      className="BlogPost"
      style={{ backgroundColor: "#F2F3F3", height: "100vh" }}
    >
      <CustomNavBar
        width={"100%"}
        overrides={{
          Title: { children: "Blog Post" },
          CustomMenuButton: {
            onClick: () => setMenu((prevMenu) => !prevMenu),
          },
          image: {
            src: ProfilePicture,
          },
        }}
      />
      {menu ? <CustomMenuBar width={"100%"} /> : null}
      {!dataLoaded && (
        <Flex justifyContent={"center"} padding={"50px"}>
          <Loader size="large"></Loader>
        </Flex>
      )}
      {dataLoaded && (
        <Flex
          justifyContent={"center"}
          padding={"20px"}
          style={{ backgroundColor: "#F2F3F3" }}
        >
          <CustomPost
            overrides={{
              Title: { children: post.title },
              Description: { children: post.description },
              image: {
                src: post.img_src,
              },
              Tag: { children: post.tags },
              Link: {
                onClick: () => {
                  window.open(post.src, "_blank", "noreferrer");
                },
              },
            }}
          />
        </Flex>
      )}
      {dataLoaded && similarPosts.length > 0 && <Divider></Divider>}
      {dataLoaded && similarPosts.length > 0 && (
        <Flex
          justifyContent={"center"}
          padding={"0px"}
          style={{ backgroundColor: "#F2F3F3" }}
        >
          <h1>Related Posts</h1>
        </Flex>
      )}
      {dataLoaded && similarPosts.length > 0 && (
        <Flex
          paddingBottom={"50px"}
          gap={"40px"}
          direction={{ base: "column", small: "column", medium: "row" }}
          alignItems={"center"}
          justifyContent={"center"}
          style={{ backgroundColor: "#F2F3F3" }}
        >
          {dataLoaded && similarPosts.length > 0 && (
            <CustomPostPreview
              overrides={{
                Title: { children: similarPosts[0].title },
                image: {
                  src: similarPosts[0].img_src,
                },
                Tag: { children: similarPosts[0].tags },
              }}
              onClick={() => {
                window.open(
                  getCurrentPage() + similarPosts[0].id,
                  "noreferrer"
                );
              }}
            />
          )}

          {dataLoaded && similarPosts.length > 1 && (
            <CustomPostPreview
              overrides={{
                Title: { children: similarPosts[1].title },
                image: {
                  src: similarPosts[1].img_src,
                },
                Tag: { children: similarPosts[1].tags },
              }}
              onClick={() => {
                window.open(
                  getCurrentPage() + similarPosts[1].id,
                  "noreferrer"
                );
              }}
            />
          )}
        </Flex>
      )}
    </div>
  );
}

async function retrievePost(PostID, setPost, setSimilarPosts, setDataLoaded) {
  try {
    DataStore.observeQuery(Post, (p) => p.id.eq(PostID)).subscribe(
      (snapshot) => {
        const { items, isSynced } = snapshot;
        // console.log(
        //   `[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`
        // );

        if (isSynced) {
          setDataLoaded(true);

          const post = items[0];

          setPost({
            title: post["title"],
            src: post["src"],
            description: post["description"],
            img_src: post["img_src"],
            tags: post["category"],
            post_date: post["post_date"],
            linkedin_likes: post["linkedin_likes"],
            linkedin_views: post["linkedin_views"],
            linkedin_post: post["linkedin_post"],
          });
          retrieveSimilarPosts(PostID, post["category"], setSimilarPosts);
        }
      }
    );
  } catch (error) {
    console.log("Error retrieving posts", error);
  }
}

async function retrieveSimilarPosts(PostID, Category, setSimilarPosts) {
  try {
    // console.log("Category: " + Category);
    const categories = Category.split(" ");
    const posts = await DataStore.query(Post, (c) =>
      c.and((c) => [c.category.contains(categories[0]), c.id.ne(PostID)]),
      { 
        sort: (s) => s.linkedin_likes(SortDirection.DESCENDING),
        limit: 10
      }
    );

    // console.log(
    //   "Categories retrieved successfully!",
    //   JSON.stringify(posts, null)
    // );    

    shuffleArray(posts);

    var result = [];
    for (let count = 0; count < 2; count++) {
      result.push({
        id: posts[count]["id"],
        title: posts[count]["title"],
        img_src: posts[count]["img_src"],
        tags: posts[count]["category"],
      });
    }
    setSimilarPosts(result);
  } catch (error) {
    console.log("Error retrieving posts", error);
  }
}

function getCurrentPage() {
  if (window.location.hostname == "localhost") {
    return (
      window.location.protocol +
      "//" +
      window.location.hostname +
      ":" +
      window.location.port +
      "/"
    );
  } else {
    return window.location.protocol + "//" + window.location.hostname + "/";
  }
}

function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
  }
}

export default BlogPost;
