/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Image, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function CustomPost(props) {
  const { post, id, overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        image: {},
        Title: {},
        Header: {},
        Description: {},
        Body: {},
        Tag: {},
        Area: {},
        Link: {},
        Source: {},
        "Frame 420": {},
        CustomPost: {},
      },
      variantValues: { breakpoint: "small" },
    },
    {
      overrides: {
        image: { width: "450px", height: "240px" },
        Title: {},
        Header: {},
        Description: {},
        Body: {},
        Tag: {},
        Area: { padding: "0px 20px 0px 20px" },
        Link: {},
        Source: {},
        "Frame 420": { borderRadius: "50px" },
        CustomPost: { width: "500px" },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        image: { width: "500px", height: "384.62px", borderRadius: "20px" },
        Title: {},
        Header: { gap: "16px" },
        Description: {
          children:
            "How do you prevent the most common merge conflicts when your team is working on a Serverless application? How do you make sure that your team stays productive and avoids large merge issues while trying to update the same crucial files simultaneously? \u2013The answer to both questions is code organization! You can use cfn-include and swagger-cli to organize, collaborate, and maintain a large serverless application as well as support a large or decentralized development team.\n\n",
        },
        Body: { gap: "8px" },
        Tag: {},
        Area: { gap: "8px", height: "1px", padding: "0px 20px 0px 20px" },
        Link: {},
        Source: {},
        "Frame 420": {
          shrink: "1",
          grow: "1",
          basis: "0",
          borderRadius: "50px",
        },
        CustomPost: { direction: "row", width: "1100px" },
      },
      variantValues: { breakpoint: "large" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="20px"
      direction="column"
      width="320px"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      boxShadow="4px 4px 4px rgba(0, 0, 0, 0.25)"
      padding="10px 10px 10px 10px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "CustomPost")}
      {...rest}
    >
      <Image
        width="270px"
        height="150px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        borderRadius="50px"
        padding="0px 0px 0px 0px"
        objectFit="cover"
        src={post?.img_src}
        {...getOverrideProps(overrides, "image")}
      ></Image>
      <Flex
        gap="20px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 420")}
      >
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 20px 0px 20px"
          display="flex"
          {...getOverrideProps(overrides, "Header")}
        >
          <Text
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.05px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={post?.title}
            {...getOverrideProps(overrides, "Title")}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 20px 0px 20px"
          display="flex"
          {...getOverrideProps(overrides, "Body")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(48,64,80,1)"
            lineHeight="24px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={post?.description}
            {...getOverrideProps(overrides, "Description")}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="flex-end"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "Area")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(64,106,191,1)"
            lineHeight="20px"
            textAlign="right"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={post?.category}
            {...getOverrideProps(overrides, "Tag")}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-end"
          shrink="0"
          position="relative"
          border="1px SOLID rgba(0,0,0,1)"
          padding="0px 19px 0px 19px"
          display="flex"
          {...getOverrideProps(overrides, "Source")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(4,52,149,1)"
            lineHeight="24px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.01px"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Read more: Click here"
            {...getOverrideProps(overrides, "Link")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
