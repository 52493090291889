/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Flex,
  Icon,
  Image,
  Text,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function CustomNavBar(props) {
  const { as, overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        image: {},
        Logo: { width: "50px", height: "50px" },
        Title: { fontSize: "40px", lineHeight: "40px" },
        label37203556: {},
        Button37203555: {},
        label37203558: {},
        Button37203557: {},
        label37203560: {},
        Button37203559: {},
        Pages: {},
        Vector: {
          width: "50px",
          height: "30px",
          viewBox: { minX: 0, minY: 0, width: 50, height: 30 },
          paths: [
            {
              d: "M50 0L0 0L0 5.47181L50 5.47181L50 0Z",
              fill: "rgba(236,114,17,1)",
              fillRule: "nonzero",
            },
            {
              d: "M50 12.2638L3.2482e-13 12.2638L3.2482e-13 17.7356L50 17.7356L50 12.2638Z",
              fill: "rgba(236,114,17,1)",
              fillRule: "nonzero",
            },
            {
              d: "M50 24.5282L3.2482e-13 24.5282L3.2482e-13 30L50 30L50 24.5282Z",
              fill: "rgba(236,114,17,1)",
              fillRule: "nonzero",
            },
          ],
        },
        CustomMenuButton: { width: "50px", height: "30px" },
        CustomNavBar: {
          gap: "40px",
          width: "757px",
          justifyContent: "flex-start",
          padding: "5px 40px 5px 40px",
        },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        image: {},
        Logo: {},
        Title: {},
        label37203556: {},
        Button37203555: {},
        label37203558: {},
        Button37203557: {},
        label37203560: {},
        Button37203559: {},
        Pages: {},
        Vector: {},
        CustomMenuButton: {},
        CustomNavBar: {},
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="20px"
      direction="row"
      width="387px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="5px 10px 5px 10px"
      backgroundColor="rgba(35,47,62,1)"
      display="flex"
      {...getOverrideProps(overrides, "CustomNavBar")}
      {...rest}
    >
      <Flex
        gap="5px"
        direction="row"
        width="30px"
        height="30px"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Logo")}
      >
        <Image
          width="unset"
          height="unset"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          grow="1"
          shrink="1"
          basis="0"
          alignSelf="stretch"
          position="relative"
          borderRadius="64px"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src="https://media.licdn.com/dms/image/C4D03AQFmM9P4xawPUA/profile-displayphoto-shrink_800_800/0/1649336832194?e=1680739200&v=beta&t=s4GQOk-0iNHGrfezMCk83UszSs7tSiMxZhMYjzq2l_s"
          {...getOverrideProps(overrides, "image")}
        ></Image>
      </Flex>
      <Text
        fontFamily="Kurale"
        fontSize="24px"
        fontWeight="400"
        color="rgba(255,255,255,1)"
        lineHeight="24px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Blog Posts"
        {...getOverrideProps(overrides, "Title")}
      ></Text>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="10px 10px 10px 10px"
        display="none"
        {...getOverrideProps(overrides, "Pages")}
      >
        <Flex
          gap="0"
          direction="row"
          width="120px"
          height="36.6px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          border="0.79px SOLID rgba(0,0,0,0)"
          borderRadius="3.1506850719451904px"
          padding="7.212328255176544px 15.212327778339386px 7.212328255176544px 15.212327778339386px"
          backgroundColor="rgba(149,4,4,1)"
          display="flex"
          as="a"
          href="/"
          {...getOverrideProps(overrides, "Button37203555")}
        >
          <Text
            fontFamily="Inter"
            fontSize="15.753425598144531px"
            fontWeight="700"
            color="rgba(255,255,255,1)"
            lineHeight="23.630138397216797px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Blog Posts"
            {...getOverrideProps(overrides, "label37203556")}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="120px"
          height="36.6px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          borderRadius="3.1506850719451904px"
          padding="7.999999523162842px 15.999999046325684px 7.999999523162842px 15.999999046325684px"
          backgroundColor="rgba(239,240,240,1)"
          display="flex"
          as="a"
          href="/resources"
          {...getOverrideProps(overrides, "Button37203557")}
        >
          <Text
            fontFamily="Inter"
            fontSize="15.753425598144531px"
            fontWeight="700"
            color="rgba(92,102,112,1)"
            lineHeight="23.630138397216797px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Resources"
            {...getOverrideProps(overrides, "label37203558")}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="120px"
          height="36.6px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          borderRadius="3.1506850719451904px"
          padding="7.999999523162842px 15.999999046325684px 7.999999523162842px 15.999999046325684px"
          backgroundColor="rgba(239,240,240,1)"
          display="flex"
          as="a"
          href="/about"
          {...getOverrideProps(overrides, "Button37203559")}
        >
          <Text
            fontFamily="Inter"
            fontSize="15.753425598144531px"
            fontWeight="700"
            color="rgba(92,102,112,1)"
            lineHeight="23.630138397216797px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            grow="1"
            shrink="1"
            basis="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="About"
            {...getOverrideProps(overrides, "label37203560")}
          ></Text>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        width="30px"
        height="20px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        borderRadius="3.1506850719451904px"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "CustomMenuButton")}
      >
        <Icon
          width="30px"
          height="20px"
          viewBox={{ minX: 0, minY: 0, width: 30, height: 20 }}
          paths={[
            {
              d: "M30 0L0 0L0 3.64788L30 3.64788L30 0Z",
              fill: "rgba(236,114,17,1)",
              fillRule: "nonzero",
            },
            {
              d: "M30 8.17587L1.94892e-13 8.17587L1.94892e-13 11.8237L30 11.8237L30 8.17587Z",
              fill: "rgba(236,114,17,1)",
              fillRule: "nonzero",
            },
            {
              d: "M30 16.3521L1.94892e-13 16.3521L1.94892e-13 20L30 20L30 16.3521Z",
              fill: "rgba(236,114,17,1)",
              fillRule: "nonzero",
            },
          ]}
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Vector")}
        ></Icon>
      </Flex>
    </Flex>
  );
}
