import React, { useState, useEffect } from "react";
import { CustomMenuBar, CustomNavBar } from "../ui-components";
import {
  Flex,
  Expander,
  ExpanderItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  ThemeProvider,
  ScrollView,
} from "@aws-amplify/ui-react";
import ProfilePicture from "./profil_picture.jpg";

const theme: Theme = {
  name: "table-theme",
  tokens: {
    components: {
      table: {
        row: {
          hover: {
            backgroundColor: { value: "{colors.orange.20}" },
          },

          striped: {
            backgroundColor: { value: "{colors.orange.10}" },
          },
        },

        header: {
          backgroundColor: { value: "{colors.orange.100}" },
        },

        // data: {
        //   fontWeight: { value: "{fontWeights.semibold}" },
        // },
      },
    },
  },
};

const themeExpander: Theme = {
  name: "expander-theme",
  tokens: {
    components: {
      expander: {
        // backgroundColor: { value: "{colors.blue.10}" },
        // borderRadius: { value: "{radii.small}" },
        trigger: {
          // minHeight: { value: "5rem" },
          // justifyContent: { value: "center" },
          _hover: {
            backgroundColor: { value: "{colors.orange.20}" },
          },
        },
        // content: {
        //   paddingInlineStart: { value: "{space.xxl}" },
        //   text: {
        //     color: { value: "{colors.blue.80}" },
        //     paddingBlockEnd: { value: "{space.large}" },
        //   },
        //   _open: {
        //     animationDuration: { value: "{time.short}" },
        //   },
        // },
      },
    },
  },
};

function About() {
  const [menu, setMenu] = useState(false);
  return (
    <div
      className="About"
      style={{ backgroundColor: "#E6E6E6", height: "100vh" }}
    >
      <CustomNavBar
        width={"100%"}
        overrides={{
          Title: { children: "About" },
          CustomMenuButton: {
            onClick: () => setMenu((prevMenu) => !prevMenu),
          },
          image: {
            src: ProfilePicture,
          },
        }}
      />
      {menu ? <CustomMenuBar width={"100%"} /> : null}
      <Flex
        justifyContent={"center"}
        padding={"20px"}
        paddingBottom={"50px"}
        direction={"column"}
        style={{ backgroundColor: "#E6E6E6" }}
      >
        <ThemeProvider theme={themeExpander}>
          <Expander
            type="multiple"
            isCollapsible={true}
            defaultValue={["item-1"]}
            style={{ fontWeight: "bold" }}
          >
            <ExpanderItem title="That's me" value="item-1">
              <p style={{ fontWeight: "normal" }}>
                Hi there! I am Flah - a passionate Cloud Architect with a love
                for building scalable and efficient cloud solutions. With a head
                in the clouds and feet firmly grounded in technology, I strive
                to create innovative architectures that propel businesses to new
                heights. <br></br> <br></br>
                My journey as a Cloud Architect has been fueled by a deep
                fascination for the ever-evolving world of cloud computing. I
                thrive on the challenge of designing robust and secure
                infrastructures that harness the power of the cloud to drive
                organizational success. From crafting highly available and
                fault-tolerant architectures to optimizing performance and cost
                efficiency, I relish in finding elegant solutions to complex
                problems. <br></br> <br></br>
                Collaboration lies at the heart of my approach. By working
                closely with cross-functional teams, I ensure that cloud
                strategies align seamlessly with business objectives. I enjoy
                translating technical jargon into clear, concise language that
                empowers stakeholders to make informed decisions. In my arsenal,
                I wield a toolkit of cloud services, infrastructure-as-code,
                automation, and DevOps principles. I am always eager to explore
                emerging technologies and stay ahead of the curve, as the cloud
                landscape continues to evolve at a rapid pace. <br></br>{" "}
                <br></br>
                Beyond my professional life, I find immense joy in the simple
                pleasures of life. The love I have for my family and friends
                forms the bedrock of my happiness. Prioritizing my fitness
                empowers me both physically and mentally, while my thirst for
                adventure and knowledge propels me to explore new horizons and
                continuously evolve. <br></br> <br></br>
                Connect with me on LinkedIn:{" "}
                <a
                  href="https://www.linkedin.com/in/flahahmad/"
                  target="_blank"
                >
                  https://www.linkedin.com/in/flahahmad/
                </a>
              </p>
            </ExpanderItem>
            <ExpanderItem title="Career" value="item-2">
              <p style={{ fontWeight: "normal" }}>
                My journey is filled with valuable experiences and significant
                contributions in the field of technology and cloud. <br></br>{" "}
                <br></br> I began my professional path at{" "}
                <a href="https://www.berenberg.de/en/about-us/" target="_blank">
                  Berenberg Bank
                </a>
                , where I spent an impressive five years, taking on different
                roles, including software tester, software developer and
                solutions architect. Berenberg Bank, founded in 1590, is a
                German private bank with a long history and a focus on private
                banking, asset management, corporate banking, and investment
                banking. It is one of the oldest banks in the world and has
                established itself as a reputable institution in Europe,
                offering a range of financial services to individuals,
                corporations, and institutional clients. This allowed me to gain
                a solid foundation in the industry and expand my skills and
                knowledge across various domains. Throughout my time at
                Berenberg, I demonstrated adaptability and versatility,
                contributing to the organization's success. <br></br> <br></br>
                Seeking new challenges, I then joined{" "}
                <a
                  href="https://www.hermesworld.com/int/about-us/"
                  target="_blank"
                >
                  Hermes
                </a>{" "}
                as an Enterprise/Solutions Architect, where I spent almost a
                year. Hermes is a well-known parcel delivery company operating
                primarily in Europe. It provides delivery services for various
                types of parcels, including e-commerce shipments, to both
                businesses and individuals. In this role, I showcased my
                expertise in designing and implementing scalable and efficient
                solutions to meet complex business requirements. I took pride in
                analyzing and architecting solutions to optimize processes and
                enhance operational efficiency, making a valuable impact during
                my time at Hermes. <br></br> <br></br>
                Driven by my passion for cloud computing, I transitioned to{" "}
                <a
                  href="https://www.fujitsu.com/global/about/purpose/"
                  target="_blank"
                >
                  Fujitsu
                </a>{" "}
                as a Cloud Architect, where I spent over two years and got
                promoted to a Senior Cloud Architect. Fujitsu is a global
                information and communication technology company headquartered
                in Japan. With a rich history dating back to 1935, Fujitsu has
                become a prominent player in the technology industry, offering a
                wide range of products, solutions, and services to businesses,
                governments, and individuals. I focused on architecting and
                deploying innovative and secure cloud solutions for clients.
                Leveraging my extensive knowledge of cloud architecture, I
                played a pivotal role in helping Fujitsu deliver cutting-edge
                solutions to their customers. My strategic thinking and
                problem-solving abilities proved vital in aligning cloud
                architectures with business objectives. <br></br> <br></br>
                Building upon my experience, I then had the privilege of joining{" "}
                <a href="https://www.aboutamazon.com/about-us" target="_blank">
                  Amazon Web Services (AWS)
                </a>{" "}
                as a Cloud Architect. AWS is a comprehensive cloud computing
                platform provided by Amazon. Launched in 2006, AWS is one of the
                leading cloud service providers, offering a wide range of
                services and solutions to individuals, businesses, and
                governments. I have been able to leverage my expertise to
                guide organizations in designing and implementing robust and
                scalable cloud infrastructures. Being well-versed in AWS
                services and solutions, I have become a trusted advisor for
                clients embarking on their cloud adoption journeys, aligning
                their architectures with their unique business needs. <br></br>{" "}
                <br></br>
                Throughout my career, I have demonstrated a strong work ethic, a
                thirst for knowledge, and an unwavering commitment to delivering
                excellence. My contributions at each organization have showcased
                my ability to navigate complex technological landscapes and
                provide innovative solutions to address business challenges.{" "}
                <br></br> <br></br>The diverse experiences I have gained in
                various roles and organizations have provided me with a unique
                perspective on the industry. This makes me a valuable asset to
                any team or project I undertake. I am continuously driven by my
                passion for technology and cloud architecture, constantly
                striving to stay at the forefront of industry trends and
                advancements. <br></br> <br></br>
                In summary, my career journey has been marked by valuable
                experiences and significant contributions at Berenberg, Hermes,
                Fujitsu, and Amazon. As a cloud architect, I have demonstrated
                expertise, adaptability, and a commitment to driving innovation
                in the field. Each career milestone has allowed me to deliver
                exceptional results and contribute to the success of the
                organizations I have been fortunate to be a part of.
              </p>
            </ExpanderItem>
            <ExpanderItem title="Home office" value="item-3">
              <p style={{ fontWeight: "normal" }}>
                As someone who has primarily worked from home for the past five
                years, I have gained extensive experience in maintaining a
                productive home office environment. Throughout this time, I have
                honed my setup to suit my specific needs. Creating an optimal
                home office setup is crucial for productivity, focus, and
                overall well-being. Your home office should be designed to
                support your work and provide a conducive environment. The
                following table includes items I daily use:
              </p>
              <ScrollView maxWidth="100%">
                <ThemeProvider theme={theme} colorMode="light">
                  <Table caption="" highlightOnHover={true} variation="striped">
                    <TableHead>
                      <TableRow>
                        <TableCell as="th" style={{ minWidth: "200px" }}>
                          Item
                        </TableCell>
                        <TableCell as="th" style={{ minWidth: "400px" }}>
                          Short-Description
                        </TableCell>
                        <TableCell as="th" style={{ minWidth: "200px" }}>
                          Link
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          3 x Curved Monitors<br></br>Samsung Odyssey G5
                        </TableCell>
                        <TableCell>
                          The Samsung Odyssey G5 monitor is a top choice for
                          productivity with its immersive curved design, high
                          refresh rate, fast response time, AMD FreeSync
                          support, vibrant visuals, eye comfort features,
                          versatile connectivity, and sleek adjustable stand.
                        </TableCell>
                        <TableCell>
                          <a href="https://amzn.to/3NN9g9X" target="_blank">
                            https://amzn.to/3NN9g9X
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          3 x Monitor arms<br></br>Invision gas spring arm
                        </TableCell>
                        <TableCell>
                          A monitor arm offers ergonomic benefits, optimal
                          viewing angles, desk space optimization, improved
                          collaboration, cable management, flexibility, and
                          future-proofing for a more comfortable and efficient
                          workstation setup.
                        </TableCell>
                        <TableCell>
                          <a href="https://amzn.to/3XoQxV8" target="_blank">
                            https://amzn.to/3XoQxV8
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Mechanical Keyboard<br></br>Razer BlackWidow Lite
                        </TableCell>
                        <TableCell>
                          The Razer BlackWidow Lite keyboard is a great choice
                          for users looking for a compact and versatile
                          keyboard. It offers a sleek design, quiet and
                          responsive keys, customizable backlighting, and
                          durable build quality, making it suitable for work
                          environments where a quieter typing experience is
                          desired.
                        </TableCell>
                        <TableCell>
                          <a href="https://amzn.to/3NObQfW" target="_blank">
                            https://amzn.to/3NObQfW
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Bluetooth Mouse<br></br>Razer Basilisk X
                        </TableCell>
                        <TableCell>
                          The Razer Basilisk X mouse offers a comfortable
                          ergonomic design, precise tracking, customizable
                          buttons, and long battery life, making it a great
                          choice for gamers and users seeking a reliable and
                          feature-rich wireless gaming mouse.
                        </TableCell>
                        <TableCell>
                          <a href="https://amzn.to/3Xus155" target="_blank">
                            https://amzn.to/3Xus155
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Microphone<br></br>Blue Yeti
                        </TableCell>
                        <TableCell>
                          The Blue Yeti microphone is a popular choice for its
                          exceptional audio quality, versatile recording
                          options, user-friendly features, and durable build,
                          making it ideal for professional audio recording,
                          podcasting, streaming, and voice-over work.
                        </TableCell>
                        <TableCell>
                          <a href="https://amzn.to/3NpHbnC" target="_blank">
                            https://amzn.to/3NpHbnC
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Camera<br></br>Logitech C925e
                        </TableCell>
                        <TableCell>
                          The Logitech C925e webcam is a great choice for
                          professional video conferencing and streaming. It
                          offers high-quality video resolution, clear audio,
                          reliable autofocus, and low-light correction. With its
                          wide field of view and easy setup, the C925e is an
                          excellent option for users who want to enhance their
                          video communication and create a professional
                          impression during virtual meetings or content
                          creation.
                        </TableCell>
                        <TableCell>
                          <a href="https://amzn.to/3CP2iuA" target="_blank">
                            https://amzn.to/3CP2iuA
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Chair<br></br>Backforce One
                        </TableCell>
                        <TableCell>
                          The Backforce chair is worth considering for its
                          ergonomic design and features that prioritize comfort
                          and support. It offers adjustable lumbar support,
                          synchronized tilting mechanism, adjustable armrests,
                          and adaptable seat depth. The chair's design promotes
                          proper posture, reduces strain on the back and neck,
                          and enhances overall sitting comfort. With its
                          customizable features and attention to ergonomics, the
                          Backforce chair can be beneficial for individuals who
                          spend extended periods sitting at a desk, helping to
                          prevent discomfort and improve productivity.
                        </TableCell>
                        <TableCell>
                          <a href="https://amzn.to/3Pxnxca" target="_blank">
                            https://amzn.to/3Pxnxca
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Adjustable Desk<br></br>Flexispot EF1
                        </TableCell>
                        <TableCell>
                          A Flexispot adjustable desk offers ergonomic
                          adjustability, customizable workspace, improved focus
                          and energy, space efficiency, easy transition,
                          durability, and potential health benefits.
                        </TableCell>
                        <TableCell>
                          <a href="https://amzn.to/42XzYAX" target="_blank">
                            https://amzn.to/42XzYAX
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Underdesk Treadmil<br></br>Citysports
                        </TableCell>
                        <TableCell>
                          The City Sports underdesk treadmill is a great choice
                          for individuals seeking to incorporate physical
                          activity into their work routine. It offers a compact
                          design that fits under a desk, allowing you to walk or
                          jog while working. The treadmill promotes an active
                          lifestyle, helps increase daily step count, boosts
                          energy levels, and can contribute to improved
                          cardiovascular health. It provides a convenient way to
                          stay active and counteract the sedentary nature of
                          desk-based work.
                        </TableCell>
                        <TableCell>
                          <a href="https://amzn.to/42YQl0b" target="_blank">
                            https://amzn.to/42YQl0b
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Noise Cancelling Headset<br></br>Bose QC 35
                        </TableCell>
                        <TableCell>
                          The Bose QC 35 headphones are worth considering for
                          their exceptional noise cancellation, superior sound
                          quality, and comfortable design. They offer a
                          immersive listening experience, effectively blocking
                          out external noise and allowing you to focus on your
                          music or audio. The QC 35 headphones have a long
                          battery life, convenient wireless connectivity, and a
                          lightweight build, making them ideal for travel,
                          commuting, or long listening sessions.
                        </TableCell>
                        <TableCell>
                          <a href="https://amzn.to/42YAcaY" target="_blank">
                            https://amzn.to/42YAcaY
                          </a>
                        </TableCell>
                      </TableRow>
                      {/* <TableRow>
                        <TableCell>Macbook</TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <a href="" target="_blank"></a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>PC</TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <a href="" target="_blank"></a>
                        </TableCell>
                      </TableRow> */}
                    </TableBody>
                  </Table>
                </ThemeProvider>
              </ScrollView>
            </ExpanderItem>
            <ExpanderItem title="Self-Improvement" value="item-4">
              <p style={{ fontWeight: "normal" }}>
                Self-Improvement is a lifelong journey that allows us to grow,
                develop, and reach our full potential. It is the process of
                consciously making efforts to enhance our skills, knowledge,
                mindset, and overall well-being.
              </p>

              <p style={{ fontWeight: "normal" }}>
                Listening to Audible is a fantastic way to engage in
                self-improvement and personal growth. Switching to audiobooks
                after reading with your eyes throughout the day can provide a
                refreshing change of pace and engage a different sense. By
                listening to audiobooks, you can give your eyes a break while
                still immersing yourself in valuable content. This allows you to
                continue your learning journey and enjoy books in a way that
                suits your preferences and maintains your enthusiasm for
                self-improvement. Here are some of my favourites:
              </p>

              <ScrollView maxWidth="100%">
                <ThemeProvider theme={theme} colorMode="light">
                  <Table caption="" highlightOnHover={true} variation="striped">
                    <TableHead>
                      <TableRow>
                        <TableCell as="th" style={{ minWidth: "200px" }}>
                          Title
                        </TableCell>
                        <TableCell as="th" style={{ minWidth: "400px" }}>
                          Short-Description
                        </TableCell>
                        <TableCell as="th" style={{ minWidth: "200px" }}>
                          Link
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Atomic Habits</TableCell>
                        <TableCell>
                          A book by James Clear that explores the power of small
                          habits and incremental changes in achieving remarkable
                          personal and professional transformations. The book
                          offers practical strategies and techniques to build
                          positive habits, break bad ones, and create a system
                          for long-term success.
                        </TableCell>
                        <TableCell>
                          <a href="https://amzn.to/3Nscm1D" target="_blank">
                            https://amzn.to/3Nscm1D
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Mindset</TableCell>
                        <TableCell>
                          A book by Carol S. Dweck that explores the concept of
                          mindset and how it affects our personal and
                          professional lives. Dweck introduces the idea of two
                          mindsets: the fixed mindset and the growth mindset.
                          She discusses how having a growth mindset, which
                          embraces challenges, learning, and resilience, can
                          lead to personal development and success.
                        </TableCell>
                        <TableCell>
                          <a href="https://amzn.to/3Xqg948" target="_blank">
                            https://amzn.to/3Xqg948
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>The 80/20 Principle</TableCell>
                        <TableCell>
                          A book by Richard Koch that explores the concept of
                          the Pareto Principle, which states that roughly 80% of
                          results come from 20% of efforts. Koch delves into how
                          this principle can be applied to various aspects of
                          life, such as productivity, business, and personal
                          success.
                        </TableCell>
                        <TableCell>
                          <a href="https://amzn.to/44joE3i" target="_blank">
                            https://amzn.to/44joE3i
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Rich Dad, Poor Dad</TableCell>
                        <TableCell>
                          A book by Robert Kiyosaki that contrasts the financial
                          philosophies of the author's two fathers: his own
                          father (poor dad) and the father of his best friend
                          (rich dad). The book challenges conventional wisdom
                          about money and emphasizes the importance of financial
                          education and mindset.
                        </TableCell>
                        <TableCell>
                          <a href="https://amzn.to/430ULDQ" target="_blank">
                            https://amzn.to/430ULDQ
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>The Power of Habit</TableCell>
                        <TableCell>
                          A book by Charles Duhigg explores the science behind
                          habits and their impact on our lives. The book delves
                          into the mechanisms of habit formation and how they
                          can be changed or leveraged to improve various aspects
                          of life.
                        </TableCell>
                        <TableCell>
                          <a href="https://amzn.to/43ZRypa" target="_blank">
                            https://amzn.to/43ZRypa
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>The Courage to Be Disliked</TableCell>
                        <TableCell>
                          A book by Ichiro Kishimi and Fumitake Koga that
                          challenges the notion that the opinions and judgments
                          of others should dictate our lives. It encourages
                          readers to embrace their true selves, live
                          authentically, and pursue their own happiness without
                          being constrained by societal expectations or the fear
                          of disapproval.
                        </TableCell>
                        <TableCell>
                          <a href="https://amzn.to/3PqzS1G" target="_blank">
                            https://amzn.to/3PqzS1G
                          </a>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Can't Hurt Me</TableCell>
                        <TableCell>
                          A book by David Goggins that chronicles the author's
                          journey from a troubled childhood to becoming a Navy
                          SEAL, ultra-endurance athlete, and motivational
                          speaker. The book explores Goggins' incredible mental
                          and physical transformation and the mindset shifts he
                          made to overcome adversity and push beyond his
                          perceived limits.
                        </TableCell>
                        <TableCell>
                          <a href="https://amzn.to/44dJmC9" target="_blank">
                            https://amzn.to/44dJmC9
                          </a>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </ThemeProvider>
              </ScrollView>
            </ExpanderItem>
          </Expander>
        </ThemeProvider>
      </Flex>
    </div>
  );
}

export default About;
