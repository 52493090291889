import React, { useState, useEffect } from "react";
import { CustomNavBar, CustomMenuBar } from "../ui-components";
import {
  Expander,
  ExpanderItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Flex,
  ThemeProvider,
  Theme,
  Image,
} from "@aws-amplify/ui-react";
import ProfilePicture from "./profil_picture.jpg";

const themeExpander: Theme = {
  name: "expander-theme",
  tokens: {
    components: {
      expander: {
        // backgroundColor: { value: "{colors.blue.10}" },
        // borderRadius: { value: "{radii.small}" },
        trigger: {
          // minHeight: { value: "5rem" },
          // justifyContent: { value: "center" },
          _hover: {
            backgroundColor: { value: "{colors.orange.20}" },
          },
        },
        // content: {
        //   paddingInlineStart: { value: "{space.xxl}" },
        //   text: {
        //     color: { value: "{colors.blue.80}" },
        //     paddingBlockEnd: { value: "{space.large}" },
        //   },
        //   _open: {
        //     animationDuration: { value: "{time.short}" },
        //   },
        // },
      },
    },
  },
};

function Resources() {
  const [menu, setMenu] = useState(false);
  return (
    <div
      className="Resources"
      style={{ backgroundColor: "#E6E6E6", height: "100vh" }}
    >
      <CustomNavBar
        width={"100%"}
        overrides={{
          Title: { children: "Resources" },
          CustomMenuButton: {
            onClick: () => setMenu((prevMenu) => !prevMenu),
          },
          image: {
            src: ProfilePicture,
          },
        }}
      />
      {menu ? <CustomMenuBar width={"100%"} /> : null}
      <Flex
        justifyContent={"center"}
        padding={"20px"}
        paddingBottom={"50px"}
        direction={"column"}
        style={{ backgroundColor: "#E6E6E6" }}
      >
        <ThemeProvider theme={themeExpander}>
          <Expander
            type="multiple"
            isCollapsible={true}
            defaultValue={["item-1"]}
            style={{ fontWeight: "bold" }}
          >
            <ExpanderItem title="What is AWS?" value="item-1">
              <p style={{ fontWeight: "normal" }}>
                AWS stands for Amazon Web Services. It is a comprehensive and
                widely-used cloud platform provided by Amazon. In simple terms,
                AWS offers a wide range of services and tools that allow
                individuals and businesses to access computing resources over
                the internet.
              </p>
              <p style={{ fontWeight: "normal" }}>
                Instead of building and maintaining physical servers and
                infrastructure, AWS enables you to store data, run applications,
                and perform various computing tasks in the cloud. The services
                provided by AWS include storage, compute power, databases,
                networking, analytics, machine learning, and much more.
              </p>
              <p style={{ fontWeight: "normal" }}>
                For more details please visit:{" "}
                <a href="https://aws.amazon.com/what-is-aws/" target="_blank">
                  https://aws.amazon.com/what-is-aws/
                </a>
              </p>
            </ExpanderItem>
            <ExpanderItem title="AWS Global Infrasturcture" value="item-2-4">
              <p style={{ fontWeight: "normal" }}>
                The AWS global infrastructure refers to the vast network of data
                centers and infrastructure that Amazon Web Services (AWS)
                operates worldwide. It is a distributed and highly redundant
                infrastructure designed to provide customers with scalable and
                reliable cloud computing services.
              </p>
              <p style={{ fontWeight: "normal" }}>
                The AWS global infrastructure consists of regions, availability
                zones, edge locations, and various supporting services:<br></br>
                <ol>
                  <li>
                    <i>Regions:</i> AWS has multiple geographic regions
                    globally, each consisting of multiple data centers located
                    in separate geographic areas. These regions are physically
                    isolated from one another to provide fault tolerance and
                    minimize the impact of localized disruptions.
                  </li>
                  <li>
                    <i>Availability Zones:</i> Within each region, AWS has
                    multiple availability zones (AZs). An availability zone is a
                    data center or cluster of data centers that are isolated
                    from one another to provide independent power, cooling, and
                    networking infrastructure. AZs are designed to be highly
                    resilient, ensuring high availability and fault tolerance
                    for applications.
                  </li>
                  <li>
                    <i>Edge Locations:</i> AWS has a network of edge locations
                    strategically placed in various cities around the world.
                    These edge locations serve as points of presence for Amazon
                    CloudFront (a content delivery network service) and Amazon
                    Route 53 (a scalable domain name system service). Edge
                    locations help deliver content and services to end-users
                    with low latency and high data transfer speeds.
                  </li>
                  <li>
                    <i>Supporting Services:</i> AWS provides additional
                    supporting services, such as Amazon Direct Connect, which
                    allows customers to establish private network connections
                    between their on-premises infrastructure and AWS. There are
                    also services like AWS Global Accelerator and AWS Transit
                    Gateway that help optimize network performance and
                    connectivity across regions.
                  </li>
                </ol>
              </p>
              <p style={{ fontWeight: "normal" }}>
                The AWS global infrastructure allows customers to deploy their
                applications and services in the region of their choice,
                providing low-latency access to end-users and enabling
                compliance with data sovereignty and residency requirements. The
                distributed nature of the infrastructure ensures high
                availability, scalability, and fault tolerance, supporting the
                needs of a wide range of businesses and organizations globally.
              </p>
              <p style={{ fontWeight: "normal" }}>
                For more details please visit:{" "}
                <a
                  href="https://aws.amazon.com/about-aws/global-infrastructure/"
                  target="_blank"
                >
                  https://aws.amazon.com/about-aws/global-infrastructure/
                </a>
              </p>
            </ExpanderItem>
            <ExpanderItem
              title="AWS Shared Responsibility Framework"
              value="item-2-2"
            >
              <p style={{ fontWeight: "normal" }}>
                The AWS Shared Responsibility Model is a concept that explains
                the division of responsibilities between Amazon Web Services
                (AWS) as the cloud service provider and the customer (you) using
                their services.
              </p>
              <p style={{ fontWeight: "normal" }}>
                In simple terms, AWS is responsible for the security "of" the
                cloud, meaning they ensure the underlying infrastructure, such
                as data centers, networks, and hardware, is secure. They provide
                robust physical security measures, reliable network
                infrastructure, and compliance certifications to protect the
                overall cloud environment.
              </p>
              <p style={{ fontWeight: "normal" }}>
                On the other hand, as the customer, you are responsible for the
                security "in" the cloud, meaning you have control over the
                security of the applications, data, and systems you build and
                run on AWS. This includes configuring your applications
                securely, managing user access and permissions, encrypting data,
                and implementing other security measures within the cloud
                environment.
              </p>
              <p style={{ fontWeight: "normal" }}>
                The division of responsibilities ensures a shared effort to
                maintain a secure cloud environment. It's essential to
                understand this model and implement appropriate security
                measures to protect your assets effectively while utilizing AWS
                services.
              </p>
              <p style={{ fontWeight: "normal" }}>
                For more details please visit:{" "}
                <a
                  href="https://aws.amazon.com/compliance/shared-responsibility-model/"
                  target="_blank"
                >
                  https://aws.amazon.com/compliance/shared-responsibility-model/
                </a>
              </p>
            </ExpanderItem>
            <ExpanderItem
              title="AWS Well Architected Framework"
              value="item-2-1"
            >
              <p style={{ fontWeight: "normal" }}>
                The AWS Well-Architected Framework is a set of best practices
                and guidelines provided by Amazon Web Services (AWS) to help you
                design and build cloud-based applications and systems in a
                secure, reliable, and efficient manner.
              </p>
              <p style={{ fontWeight: "normal" }}>
                Think of it as a blueprint or a checklist that helps you ensure
                your applications are well-designed and follow industry-leading
                standards. It covers various aspects of architecture, including
                performance, security, cost optimization, reliability, and
                operational excellence.
              </p>
              <p style={{ fontWeight: "normal" }}>
                Let's break down the 6 pillars of the AWS Well-Architected
                Framework:<br></br>
                <ol>
                  <li>
                    <i>Security:</i> This pillar focuses on protecting your
                    systems and data from unauthorized access and ensuring data
                    privacy. It includes practices such as implementing strong
                    access controls, encryption, and monitoring for security
                    incidents.
                  </li>
                  <li>
                    <i>Reliability:</i> This pillar emphasizes the importance of
                    building systems that can withstand failures and quickly
                    recover from them. It involves using techniques like
                    redundancy, fault tolerance, and automated backups to
                    minimize downtime and ensure continuous availability.
                  </li>
                  <li>
                    <i>Performance Efficiency:</i> This pillar is about
                    optimizing the performance of your applications and systems
                    to deliver the best user experience. It includes strategies
                    like load balancing, caching, and efficient resource
                    utilization to maximize speed and responsiveness.
                  </li>
                  <li>
                    <i>Cost Optimization:</i> This pillar helps you optimize
                    your cloud resources and minimize unnecessary expenses. It
                    involves monitoring and controlling costs, leveraging
                    cost-effective services, and scaling resources based on
                    demand to achieve cost-efficiency.
                  </li>
                  <li>
                    <i>Operational Excellence:</i> This pillar focuses on
                    streamlining operations and improving efficiency in managing
                    and running your systems. It covers areas such as
                    automation, continuous improvement, and well-defined
                    operational processes to enhance agility and reduce manual
                    work.
                  </li>
                  <li>
                    <i>Sustainability:</i> This pillar describes how adding
                    sustainability to the list of your business requirements can
                    result in more cost-effective solutions. Focusing on getting
                    more value from the resources you use and using fewer of
                    them directly translates to cost savings on AWS as you pay
                    only for what you use.
                  </li>
                </ol>
              </p>
              <p style={{ fontWeight: "normal" }}>
                By following the AWS Well-Architected Framework, you can ensure
                that your applications and systems are designed to be secure,
                reliable, performant, cost-effective, and easily manageable. It
                provides you with a structured approach to building and
                maintaining high-quality cloud-based solutions.
              </p>
              <p style={{ fontWeight: "normal" }}>
                For more details please visit:{" "}
                <a
                  href="https://aws.amazon.com/architecture/well-architected/"
                  target="_blank"
                >
                  https://aws.amazon.com/architecture/well-architected/
                </a>
              </p>
            </ExpanderItem>
            <ExpanderItem
              title="AWS Data Residency & Compliance"
              value="item-2-3"
            >
              <p style={{ fontWeight: "normal" }}>
                AWS data residency refers to the concept of where your data is
                physically stored and resides within AWS infrastructure. AWS
                operates data centers across different regions worldwide, and
                each region consists of multiple availability zones, which are
                separate facilities within a region.
              </p>
              <p style={{ fontWeight: "normal" }}>
                When you use AWS services, such as storing data in Amazon S3 or
                running applications on Amazon EC2, you have the option to
                choose the specific AWS region where your data will be stored.
                The region you select determines the physical location of your
                data and the laws and regulations that govern it.
              </p>
              <p style={{ fontWeight: "normal" }}>
                Data residency is significant because it can affect factors such
                as data privacy, compliance requirements, and latency. Different
                countries and regions may have distinct laws and regulations
                regarding the storage, processing, and transfer of data. By
                selecting a specific AWS region, you can ensure that your data
                complies with the legal and regulatory requirements of that
                region.
              </p>
              <p style={{ fontWeight: "normal" }}>
                Additionally, AWS offers services like AWS Outposts and AWS
                Local Zones, which extend AWS infrastructure closer to specific
                geographic locations. These services can be used to address data
                residency requirements that mandate data to be stored within a
                specific country or region.
              </p>
              <p style={{ fontWeight: "normal" }}>
                For more details please visit:{" "}
                <a
                  href="https://aws.amazon.com/architecture/security-identity-compliance/"
                  target="_blank"
                >
                  https://aws.amazon.com/architecture/security-identity-compliance/
                </a>
              </p>
            </ExpanderItem>
            <ExpanderItem
              title="Why get AWS certified?"
              value="item-3-1"
              style={{ fontWeight: "bold" }}
            >
              <p style={{ fontWeight: "normal" }}>
                There are several compelling reasons to pursue AWS
                certifications:
                <br></br>
                <ol>
                  <li>
                    <i>Industry Recognition:</i> AWS certifications are highly
                    regarded in the IT industry and recognized worldwide.
                    Achieving an AWS certification demonstrates your expertise
                    and proficiency in using AWS services and solutions. It can
                    help you stand out among your peers and enhance your
                    professional credibility.
                  </li>
                  <li>
                    <i>Career Advancement:</i> AWS certifications can open up
                    new career opportunities and increase your marketability. As
                    more organizations adopt cloud technologies, the demand for
                    professionals with AWS skills is growing. Holding an AWS
                    certification can give you a competitive edge and increase
                    your chances of landing a job or advancing your career
                    within the industry.
                  </li>
                  <li>
                    <i>Knowledge and Skill Validation:</i> reparing for an AWS
                    certification requires in-depth learning and understanding
                    of AWS services, architectures, and best practices. By
                    earning a certification, you validate your knowledge and
                    skills in designing, deploying, and managing applications on
                    the AWS platform. This can boost your confidence and enable
                    you to deliver higher-quality solutions to your organization
                    or clients.
                  </li>
                  <li>
                    <i>Access to Exclusive Resources:</i> As an AWS certified
                    professional, you gain access to a range of exclusive
                    resources, including AWS Certified Global Community forums,
                    webinars, and events. These resources provide opportunities
                    to network with other certified professionals, share
                    experiences, and gain insights into the latest developments
                    in the AWS ecosystem.
                  </li>
                  <li>
                    <i>Continuous Learning and Growth:</i> AWS certifications
                    are designed to keep up with the rapidly evolving cloud
                    landscape. Maintaining your certification requires ongoing
                    learning and staying updated with AWS services and features.
                    This encourages continuous professional development and
                    keeps you informed about the latest cloud technologies.
                  </li>
                  <li>
                    <i>Partner Program Benefits:</i> If you work for an AWS
                    Partner Network (APN) company or plan to join one, AWS
                    certifications can offer additional advantages. Certain
                    partner programs require a specific number of certified
                    individuals within the organization to achieve partner
                    status or unlock program benefits.
                  </li>
                </ol>
              </p>
              <p style={{ fontWeight: "normal" }}>
                Overall, AWS certifications can enhance your career prospects,
                validate your skills, provide access to valuable resources, and
                demonstrate your commitment to professional development. They
                are highly respected in the industry and can help you stay
                relevant in the fast-paced world of cloud computing.
              </p>
            </ExpanderItem>
            <ExpanderItem
              title="Certifications overview"
              value="item-3-2"
              style={{ fontWeight: "bold" }}
            >
              <p style={{ fontWeight: "normal" }}>Foundational Level</p>
              <ul style={{ fontWeight: "normal" }}>
                <li>
                  <i>AWS Certified Cloud Practitioner:</i> Validates basic knowledge of
                  AWS Cloud services, architecture, billing, and security.
                </li>
              </ul>

              <p style={{ fontWeight: "normal" }}>Associate Level</p>
              <ul style={{ fontWeight: "normal" }}>
                <li>
                  <i>AWS Certified Solutions Architect - Associate:</i> Focuses on
                  designing and deploying scalable systems on AWS.
                </li>
                <li>
                  <i>AWS Certified Developer - Associate:</i> Covers developing and
                  maintaining applications on the AWS platform.
                </li>
                <li>
                  <i>AWS Certified SysOps Administrator - Associate:</i> Validates
                  skills in deploying and managing systems on AWS.
                </li>
              </ul>

              <p style={{ fontWeight: "normal" }}>Professional Level</p>
              <ul style={{ fontWeight: "normal" }}>
                <li>
                  <i>AWS Certified Solutions Architect - Professional:</i> Validates
                  advanced knowledge in designing and deploying highly available
                  systems on AWS.
                </li>
                <li>
                  <i>AWS Certified DevOps Engineer - Professional:</i> Focuses on
                  provisioning and managing distributed application systems on
                  AWS.
                </li>
              </ul>

              <p style={{ fontWeight: "normal" }}>Specialty Certifications</p>
              <ul style={{ fontWeight: "normal" }}>
                <li>
                  <i>AWS Certified Advanced Networking - Specialty:</i> Covers
                  designing and implementing advanced networking solutions on
                  AWS.
                </li>
                <li>
                  <i>AWS Certified Security - Specialty:</i> Focuses on securing AWS
                  workloads and infrastructure.
                </li>
                <li>
                  <i>AWS Certified Machine Learning - Specialty:</i> Validates
                  expertise in designing and implementing machine learning
                  solutions on AWS.
                </li>
                <li>
                  <i>AWS Certified Data Analytics - Specialty:</i> Covers designing and
                  implementing analytics solutions on AWS.
                </li>
                <li>
                  <i>AWS Certified Database - Specialty:</i> Validates expertise in
                  recommending, designing, and maintaining optimal AWS database
                  solutions
                </li>
                <li>
                  <i>AWS Certified: SAP on AWS - Specialty:</i> Validates expertise in
                  designing, implementing, migrating, and operating SAP
                  workloads on AWS
                </li>
              </ul>
              <p style={{ fontWeight: "normal" }}>
                For more details please visit:{" "}
                <a
                  href="https://aws.amazon.com/certification/exams/"
                  target="_blank"
                >
                  https://aws.amazon.com/certification/exams/
                </a>
              </p>
            </ExpanderItem>
            <ExpanderItem
              title="Learning resources"
              value="item-3-3"
              style={{ fontWeight: "bold" }}
            >
              {/* TODO: Add all bookmarks and stuff */}
              <p style={{ fontWeight: "normal" }}>Available soon!</p>
            </ExpanderItem>
            {/* TODO: Add details for referrals */}
            {/* <ExpanderItem
            title="Jobs at Amazon & Referral"
            value="item-4"
            style={{ fontWeight: "bold" }}
          ></ExpanderItem> */}
          </Expander>
        </ThemeProvider>
      </Flex>
    </div>
  );
}

export default Resources;
