/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, useBreakpointValue } from "@aws-amplify/ui-react";
export default function CustomMenuBar(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        label37652844: { fontSize: "18px", lineHeight: "27px" },
        Button37652843: { width: "120px" },
        label37652846: { fontSize: "18px", lineHeight: "27px" },
        Button37652845: { width: "120px" },
        label37652848: { fontSize: "18px", lineHeight: "27px" },
        Button37652847: { width: "120px" },
        Pages: {},
        CustomMenuBar: {},
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        label37652844: {},
        Button37652843: {},
        label37652846: {},
        Button37652845: {},
        label37652848: {},
        Button37652847: {},
        Pages: {},
        CustomMenuBar: {},
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="40px"
      direction="row"
      width="1329px"
      height="unset"
      justifyContent="flex-end"
      alignItems="center"
      position="relative"
      padding="5px 5px 5px 5px"
      backgroundColor="rgba(35,47,62,1)"
      display="flex"
      {...getOverrideProps(overrides, "CustomMenuBar")}
      {...rest}
    >
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="10px 10px 10px 10px"
        display="flex"
        {...getOverrideProps(overrides, "Pages")}
      >
        <Flex
          gap="0"
          direction="row"
          width="100px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          border="1px SOLID rgba(0,0,0,1)"
          borderRadius="3.1506850719451904px"
          padding="6.999999523162842px 14.999999046325684px 6.999999523162842px 14.999999046325684px"
          backgroundColor="rgba(236,114,17,1)"
          display="flex"
          as="a"
          href="/"
          {...getOverrideProps(overrides, "Button37652843")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="700"
            color="rgba(255,255,255,1)"
            lineHeight="21px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Blog Posts"
            {...getOverrideProps(overrides, "label37652844")}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="100px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          border="1px SOLID rgba(0,0,0,1)"
          borderRadius="3.1506850719451904px"
          padding="6.999999523162842px 14.999999046325684px 6.999999523162842px 14.999999046325684px"
          backgroundColor="rgba(236,114,17,1)"
          display="flex"
          as="a"
          href="/resources"
          {...getOverrideProps(overrides, "Button37652845")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="700"
            color="rgba(255,255,255,1)"
            lineHeight="21px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Resources"
            {...getOverrideProps(overrides, "label37652846")}
          ></Text>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="100px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          border="1px SOLID rgba(0,0,0,1)"
          borderRadius="3.1506850719451904px"
          padding="6.999999523162842px 14.999999046325684px 6.999999523162842px 14.999999046325684px"
          backgroundColor="rgba(236,114,17,1)"
          display="flex"
          as="a"
          href="/about"
          {...getOverrideProps(overrides, "Button37652847")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="700"
            color="rgba(255,255,255,1)"
            lineHeight="21px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="About"
            {...getOverrideProps(overrides, "label37652848")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
