/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import {
  Flex,
  SelectField,
  Text,
  useBreakpointValue,
} from "@aws-amplify/ui-react";
export default function CustomSortBar(props) {
  const { overrides: overridesProp, ...restProp } = props;
  const variants = [
    {
      overrides: {
        Sort37143513: { color: "rgba(255,255,255,1)" },
        SelectField37143514: {},
        "Frame 427": { display: "flex" },
        Category37143516: { color: "rgba(255,255,255,1)" },
        SelectField37143517: {},
        "Frame 428": { display: "flex" },
        Sort37082967: { color: "rgba(0,0,0,1)" },
        Category37082970: { color: "rgba(0,0,0,1)" },
        "Frame 429": { display: "none" },
        SelectField37082968: {},
        SelectField37082971: {},
        "Frame 430": { display: "none" },
        CustomSortBar: { gap: "20px", padding: "10px 10px 10px 10px" },
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        Sort37143513: {
          fontSize: "18px",
          color: "rgba(255,255,255,1)",
          lineHeight: "24px",
        },
        SelectField37143514: { width: "200px", size: "small" },
        "Frame 427": { justifyContent: "center", display: "flex" },
        Category37143516: {
          fontSize: "18px",
          color: "rgba(255,255,255,1)",
          lineHeight: "24px",
        },
        SelectField37143517: { width: "200px", size: "small" },
        "Frame 428": { justifyContent: "center", display: "flex" },
        Sort37082967: { color: "rgba(0,0,0,1)" },
        Category37082970: { color: "rgba(0,0,0,1)" },
        "Frame 429": { display: "none" },
        SelectField37082968: {},
        SelectField37082971: {},
        "Frame 430": { display: "none" },
        CustomSortBar: { gap: "20px", padding: "10px 10px 10px 10px" },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        Sort37143513: {},
        SelectField37143514: {},
        "Frame 427": {},
        Category37143516: {},
        SelectField37143517: {},
        "Frame 428": {},
        Sort37082967: {},
        Category37082970: {},
        "Frame 429": {},
        SelectField37082968: {},
        SelectField37082971: {},
        "Frame 430": {},
        CustomSortBar: {},
      },
      variantValues: { breakpoint: "small" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "small",
    small: "small",
    medium: "medium",
    large: "large",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="5px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="5px 10px 5px 10px"
      backgroundColor="rgba(51,68,88,1)"
      display="flex"
      {...getOverrideProps(overrides, "CustomSortBar")}
      {...rest}
    >
      <Flex
        gap="20px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="none"
        {...getOverrideProps(overrides, "Frame 427")}
      >
        <Text
          fontFamily="Kurale"
          fontSize="30px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="30px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Sort by:"
          {...getOverrideProps(overrides, "Sort37143513")}
        ></Text>
        <SelectField
          width="250px"
          height="unset"
          placeholder="Latest"
          shrink="0"
          size="large"
          isDisabled={false}
          labelHidden={true}
          variation="default"
          {...getOverrideProps(overrides, "SelectField37143514")}
        ></SelectField>
      </Flex>
      <Flex
        gap="20px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="none"
        {...getOverrideProps(overrides, "Frame 428")}
      >
        <Text
          fontFamily="Kurale"
          fontSize="30px"
          fontWeight="400"
          color="rgba(0,0,0,1)"
          lineHeight="30px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Category:"
          {...getOverrideProps(overrides, "Category37143516")}
        ></Text>
        <SelectField
          width="250px"
          height="unset"
          placeholder="All"
          shrink="0"
          size="large"
          isDisabled={false}
          labelHidden={true}
          variation="default"
          {...getOverrideProps(overrides, "SelectField37143517")}
        ></SelectField>
      </Flex>
      <Flex
        gap="20px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 429")}
      >
        <Text
          fontFamily="Kurale"
          fontSize="18px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="18px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Sort by:"
          {...getOverrideProps(overrides, "Sort37082967")}
        ></Text>
        <Text
          fontFamily="Kurale"
          fontSize="18px"
          fontWeight="400"
          color="rgba(255,255,255,1)"
          lineHeight="18px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Category:"
          {...getOverrideProps(overrides, "Category37082970")}
        ></Text>
      </Flex>
      <Flex
        gap="5px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Frame 430")}
      >
        <SelectField
          width="200px"
          height="unset"
          placeholder="Latest"
          shrink="0"
          size="small"
          isDisabled={false}
          labelHidden={true}
          variation="default"
          {...getOverrideProps(overrides, "SelectField37082968")}
        ></SelectField>
        <SelectField
          width="200px"
          height="unset"
          placeholder="All"
          shrink="0"
          size="small"
          isDisabled={false}
          labelHidden={true}
          variation="default"
          {...getOverrideProps(overrides, "SelectField37082971")}
        ></SelectField>
      </Flex>
    </Flex>
  );
}
